import {
  Col,
  Form,
  Row,
  Button,
  Input,
  message,
  Table,
  Modal,
  Select,
} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  AlignCenterOutlined,
} from '@ant-design/icons';
import axiosInstance from '../../axiosInstance';
import {UserContext} from '../../globalContext';
import {PageTitle} from '../../PageTitle';
import moment from 'moment';
import logo from '../../images/hemadesiludo.png';
import face from '../../images/face8.jpg';
import wollet from '../../images/wallet.32eb69bea0c4c697ebf9.png';
import ImageModal from './imageModel';

const {Option} = Select;
const RechargeAdmin = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleToggleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  let defaultDate = new Date();
  const user = useContext(UserContext);
  const [allProfit, setAllProfit] = useState();

  const [PaymentData, setPaymentData] = useState([]);
  const [readSnfObj, setReadSnfObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionDate, setTransactionDate] = useState(defaultDate);

  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);

  const pageLoad = () => {
    getPayment();
    onSetDate();
    getAllProfit();
  };

  const onSetDate = () => {
    setTransactionDate(new Date());
  };
 

  const getPayment = () => {
    console.log("requstedData")

    axiosInstance.post(`/payment/all`).then(response => {
      console.log("requstedData",response.data.data)
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate),
      );
      setPaymentData(sortedData);
    });
  };

  const removePayment = obj => {
    Modal.confirm({
      title: 'Do you want to reject this payment?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        axiosInstance
          .put(`/payment/${obj._id}`, {status: 'failed'})
          .then(res => {
            if (res.data && res.data.responseCode === 1) {
              message.success('Record reject successfully');
              getPayment();
            } else message.error('Something wrong. Please try again...!');
          });
      },
      onCancel() {},
    });
  };
  const confirmPayment = obj => {
    var addAmount = obj.amount;
    var addBonus = obj.bonus;
    var walletAmount;
    axiosInstance.get(`wallet/${obj.member._id}`).then(response => {
      walletAmount = response.data.data.depositeAmount;
    });
    Modal.confirm({
      title: 'Do you want to confirm this payment?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        checkRefral(obj)
        if (obj.status === 'requested') {
          axiosInstance
            .put(`/payment/${obj._id}`, {status: 'success'})
            .then(res => {
              if (res.data && res.data.responseCode === 1) {
                axiosInstance.get(`wallet/${obj.member._id}`).then(response => {
                  const data = {
                    depositeAmount:
                      response.data.data.depositeAmount + addAmount,
                      bonus:response.data.data.bonus + addBonus,
                      avlbonus:response.data.data.avlbonus + addBonus
                  };
                  axiosInstance
                    .put(`wallet/${obj.member._id}`, data)
                    .then(response => {});
                  message.success('Record confirm successfully');
                  getPayment();
                });
              } else message.error('Something wrong. Please try again...!');
            });
        }
      },
      onCancel() {},
    });
  };
  const checkRefral = (obj) => {
   if(obj.member.firstRecharge == false){
    addRefralPayment(obj.member.referCode)
    axiosInstance.put(`member/update/${obj.member._id}`,{firstRecharge:true})
   }
  };
  const addRefralPayment = (obj) => {
   const data ={
    memberReferCode:obj
   }

    axiosInstance.post('member/refral',data).then((res)=>{
      console.log("data",res.data.data[0].wallet)
    const data1 ={
      RefralWinningAmount:res.data.data[0].wallet.RefralWinningAmount+50,
      avlbonus:res.data.data[0].wallet.avlbonus + 50
    }
    axiosInstance
    .put(`wallet/${res.data.data[0].wallet.member}`, data1)
    .then(response => {console.log(response)});

    })
   };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = `/`;
  };
  const getAllProfit = () => {
    axiosInstance.post(`/profit/report`).then(response => {
      if (response.data.data.length > 0) {
        setAllProfit(...response.data.data);
      } else {
        setAllProfit({amount: 0});
      }
    });
  };






  const [searchQuery, setSearchQuery] = useState('');

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter PaymentData based on search query
  var filteredData = PaymentData.filter(data => {
    const member = data.member;
    return (
      data.id.toString().includes(searchQuery) ||
      (member && member.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (member && member.mobile.toLowerCase().includes(searchQuery.toLowerCase())) ||
      data.amount.toString().includes(searchQuery) ||
      moment(data.transactionDate).format('DD-MM-yyyy').includes(searchQuery) ||
      data.status.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });









  const [totalPage, setTotalPage] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // You can change this value to set the number of items per page

  // Calculate the indices for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        filteredData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const pageNumbers = [];

  // Handle page change
  const handlePageChange = (pageNumber) => {
    if((pageNumber <= pageNumbers.length ) && pageNumber>0){
    setCurrentPage(pageNumber);
    }
  };

  // Render pagination controls
  const renderPagination = () => {
    const totalPages = Math.ceil(PaymentData.length / itemsPerPage);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }   
    //  return (
    //   <>
      
          
    //       {pageNumbers.map(number => (
    //         <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`} class="page-item">
    //           <a onClick={() => handlePageChange(number)} class="page-link">
    //             {number}
    //           </a>
              
    //         </li>
    //       ))}
        
    //   </>
    // );
  };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);



  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <div>
      <div className={` ${isSidebarVisible ? 'sidebar-icon-only' : ''}`}>
        {/* //nav top header start */}
        <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <a class="navbar-brand brand-logo" href="javascriptvoid:(0)">
              <img src={logo} alt="logo" class="logo-dark" />
            </a>
            <a class="navbar-brand brand-logo-mini" href="javascriptvoid:(0)"></a>
            <button
              class="navbar-toggler navbar-toggler align-self-center"
              type="button"
              data-toggle="minimize"
              onClick={handleToggleClick}>
              <span class="icon-menu"></span>
            </button>
          </div>
          <div class="navbar-menu-wrapper d-flex align-items-center">
            <h5 class="mb-0 font-weight-medium d-none d-lg-flex">
              Welcome Your dashboard!
            </h5>
            <ul class="navbar-nav navbar-nav-right">
              <li>
                <div>
                  <a
                    class="text-decoration-none text-white "
                    href="javascriptvoid:(0)">
                    <div class="py-1 bg-white border px-2 text-dark d-flex align-items-center rounded-2">
                      <img className="wllet" src={wollet} alt="" srcset="" />
                      {allProfit === undefined && <strong>0</strong>}
                      {allProfit !== undefined && (
                        <strong>{allProfit.amount}</strong>
                      )}
                    </div>
                  </a>
                </div>
              </li>

              <li class="nav-item dropdown d-none d-xl-inline-flex user-dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  id="UserDropdown"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img
                    class="img-xs rounded-circle ms-2"
                    src={face}
                    alt="Profile image"
                  />
                  <span class="font-weight-normal"> Admin </span>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-right navbar-dropdown"
                  aria-labelledby="UserDropdown">
                  <div class="dropdown-header text-center">
                    <img
                      class="img-md rounded-circle"
                      src={face}
                      alt="Profile image"
                    />
                    <p class="mb-1 mt-3">Admin</p>
                    <p class="font-weight-light text-muted mb-0">
                      Admin@gmail.com
                    </p>
                  </div>

                  <a class="dropdown-item" onClick={() => handleLogout()}>
                    <i class="dropdown-item-icon icon-power text-primary"></i>
                    Log Out
                  </a>
                </div>
              </li>
            </ul>
            <button
              class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              data-toggle="offcanvas"
              onClick={handleToggleClick}>
              <span class="icon-menu"></span>
            </button>
          </div>
        </nav>
        {/* //nav top header end */}

        <div class="container-scroller">
          <div class="container-fluid page-body-wrapper">
            {/* //sidebar start */}

            <nav
            class={`sidebar sidebar-offcanvas ${
              isSidebarVisible ? 'sidebar sidebar-offcanvas active' : ''
            }`}
            id="sidebar">
            <div
              class=""
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical">
              <ul class="nav">
                <li class="nav-item navbar-brand-mini-wrapper">
                  <a
                    class="nav-link navbar-brand brand-logo-mini"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true">
                    <i class="icon-screen-desktop menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item nav-profile">
                  <a href="javascript:void(0)" class="nav-link ">
                    <div class="profile-image">
                      <img
                        class="img-xs rounded-circle"
                        src={face}
                        alt="profile image"
                      />
                      <div class="dot-indicator bg-success"></div>
                    </div>
                    <div class="text-wrapper">
                      <p class="profile-name">Admin</p>
                      <p class="designation">Administrator</p>
                    </div>
                    <div class="icon-container mt-3">
                      <i class="icon-bubbles"></i>
                      <div class="dot-indicator bg-danger"></div>
                    </div>
                  </a>
                </li>

                <li class="nav-item active">
                  <a
                    class="nav-link active"
                  
                     href="#/HomeScreen">
                    <span class="menu-title">Dashboard</span>
                    <i class="icon-screen-desktop menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/ARecharge"
                   >
                    <span class="menu-title">Recharge Request</span>
                    <i class="icon-energy menu-icon"></i>

                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/AllRecharge"
                   >
                    <span class="menu-title"> All Recharge</span>
                    <i class="icon-energy menu-icon"></i>

                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/AllWithdraw"
                   >
                    <span class="menu-title"> All Withdraw</span>
                    <i class="icon-wallet menu-icon"></i>

                    
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                 
                   
                    href="#/member"
                    >
                    <span class="menu-title">Active User</span>
                    <i class="icon-user menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#/deletedMember">
                    <span class="menu-title" >Deleted User</span>
                    <i class="icon-user-unfollow menu-icon"></i>

                  </a>
                </li>
             
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fCGame1"

                   >
                    <span class="menu-title">Reported Cancel G1</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fCGame2"
                   >
                    <span class="menu-title">Reported Cancel G2</span>
                    <i class="icon-wallet menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fGame1">
                   
                    <span class="menu-title">Reported Game 1</span>
                    <i class="icon-trophy menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fGame2"
                   >
                    <span class="menu-title">Reported Game 2</span>
                    <i class="icon-grid menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/cGame1"
                   >
                    <span class="menu-title">Cancled game 1</span>
                    <i class="icon-disc menu-icon"></i>
                  </a>
                </li>
           
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/cGame2" >
                    <span class="menu-title">Cancled game2</span>
                    <i class="icon-shield menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/sGame1"
                  >
                    <span class="menu-title">Success game 1</span>
                    <i class="icon-user-follow menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/sGame2" >
                    <span class="menu-title">Success game2</span>
                    <i class="icon-user-unfollow menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/UpiScreen" >
                    <span class="menu-title">Account</span>
                    <i class="icon-user-unfollow menu-icon"></i>
                  </a>
                </li>
     
              </ul>
            </div>
          </nav>
            {/* //sidebar end */}

            <div class="main-panel">
              <div class="content-wrapper">
                <div>
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab">
                      {/* your content */}

                      <div className="container-fluid">
                        <div className="text-center main-heading">
                          <div class="row">
                            <div class="col-md-12 grid-margin stretch-card ">
                              <div class="card">
                                <div class="card-body">
                                  <div class="d-sm-flex align-items-center mb-4">
                                    <h4 class="card-title mb-sm-0">
                                    recharge request
                                    </h4>
                                    <span
                                      
                                      class="text-dark ms-auto mb-3 mb-sm-0">
                                        {/* Search Input */}
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        className='searchadd'
        onChange={handleSearchChange}
      />
                                    </span>
                                  </div>
              
                                  <div class="table-responsive border rounded p-1">
                                  <div>
   

      <table className="table">
        <thead>
          <tr>
            <th className="font-weight-bold">ID</th>
            <th className="font-weight-bold">Name</th>
            <th className="font-weight-bold">Amount</th>
            <th className="font-weight-bold">Bonus</th>
            <th className="font-weight-bold">Transaction Date</th>
            <th className="font-weight-bold">UTR NUMBER</th>
            <th className="font-weight-bold">Status</th>
            <th className="font-weight-bold">SS</th>
            <th className="font-weight-bold">Action</th>
          </tr>
        </thead>
        {filteredData && filteredData[0] && filteredData.map(data => {
          const member = data.member;
          return (
            <tbody key={data.id}>
              <tr>
                <td>{data.id}</td>
                {data.member !== null ? (
                  <td>
                    <b>{data.member.name}</b>
                    <br />
                    <b>{data.member.mobile}</b>
                  </td>
                ) : (
                  <td></td>
                )}
                <td>{data.amount}</td>
                <td>{data.bonus}</td>
                <td>
                  <div>
                    {moment(data.transactionDate).format('DD-MM-yyyy')}
                  </div>
                  <div className="text-danger" style={{ fontWeight: 'bold' }}>
                    {moment(data.transactionDate).format('hh.mm.ss')}
                  </div>
                </td>
                <td>{data.utr}</td>
                <td>{data.status}</td>
                <td>
                  <div onClick={()=>openModal(data.image)}> <img src={data.image}></img></div>
                 
                </td>
                <td>
                  <div
                    className="badge badge-success me-1"
                    style={{ cursor: 'pointer' }}
                    onClick={() => confirmPayment(data)}
                  >
                    Approve
                  </div>
                  <div
                    className="badge badge-danger me-1"
                    style={{ cursor: 'pointer' }}
                    onClick={() => removePayment(data)}
                  >
                    Reject
                  </div>
                  {/* <div
                    className="badge badge-primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => confirmRemovePayment(data)}
                  >
                    Remove
                  </div> */}
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
                                  </div>
                                  <div class="d-flex mt-4 flex-wrap align-items-center">
                                  {currentPage === 1 && <p class="text-muted mb-sm-0">
                                    Showing 1 to 20 of {PaymentData.length} entries
                                  </p>}
                                 {currentPage > 1 && <p class="text-muted mb-sm-0">
                                    Showing {currentPage*20 -20} to {currentPage*20 } of {PaymentData.length} entries
                                  </p>}
                                    <nav class="ms-auto">
                                      <ul class="pagination separated pagination-info mb-sm-0">
                                        <li class="page-item">
                                          <a class="page-link" onClick={() => handlePageChange(currentPage-1)}>
                                            <i class="icon-arrow-left"></i>
                                          </a>
                                        </li>
                                        {renderPagination()}

                                        <li class="page-item">
                                          <a class="page-link" onClick={() => handlePageChange(currentPage+1)}>
                                            <i class="icon-arrow-right"></i>
                                          </a>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* your content end */}
                    </div>
                  </div>
                </div>
              </div>
<ImageModal isOpen={isOpen} onClose={closeModal} imageUrl={selectedImage} />
              <footer class="footer">
                <div class="d-sm-flex justify-content-center justify-content-sm-between">
                  <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2024 Thartech. All rights reserved.{' '}
                    <a href="#"> Terms of use</a>
                    <a href="#">Privacy Policy</a>
                  </span>
                  <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                    {' '}
                    Made By <i class="icon-heart text-danger"></i> Thartech
                  </span>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default RechargeAdmin;

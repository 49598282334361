import React, { useContext, useEffect, useRef, useState } from "react";
import {
    message,
} from "antd";
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";

function SolveCGame2() {
    const user = useContext(UserContext);
    let navigate = useNavigate();
    const [image, setImage] = useState(null);
    const [wallet1, setWallet1] = useState(null);
    const [wallet2, setWallet2] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');


    const { data } = useParams();
    const decodedData = JSON.parse(decodeURIComponent(data));
    const originalDate1 = new Date(decodedData.gameDate);
    console.log("decodeData", decodedData.game.player1)
    useEffect(() => {

        let mounted = true;
        if (mounted) {

        }
        pageLoad();
        return () => (mounted = false);
    }, []);
    const pageLoad = () => {
        getWallet1();
        getWallet2();
    }
    const getWallet1 = () => {
        axiosInstance.get(`/wallet/${decodedData.game.player1}`).then((res) => {
            setWallet1(res.data.data);
            console.log("wallet1", res.data.data)
        });
    }
    const getWallet2 = () => {
        axiosInstance.get(`/wallet/${decodedData.game.player2}`).then((res) => {
            setWallet2(res.data.data);
            console.log("wallet2", res.data.data)
        });
    }
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const Confirm = () => {
        if (selectedOption === "player1") {
            const data = {
                winningAmount: decodedData.winningAmount + wallet1.winningAmount,
                winningFreezAmount: 0
            }
            axiosInstance.put(`wallet/${decodedData.game.player1}`, data).then((res) => {
                if (res.data && res.data.responseCode === 1) {
                    const data1 = {
                        status: "solved"
                    }
                    axiosInstance.put(`game2F/${decodedData._id}`, data1).then((res) => {
                        if (res.data && res.data.responseCode === 1) {
                            const data2 = {
                                status: "success",
                                player1Status: "winner",
                                player2Status: "looser",
                                winner: "player1",
                            }
                            axiosInstance.put(`game2/${decodedData.game._id}`, data2).then((res) => {

                                if (res.data && res.data.responseCode === 1) {
                                    message.success("Success");
                                    navigate('/fCGame1');

                                } else message.error("Something wrong. Please try again...!");
                            });
                        } else message.error("Something wrong. Please try again...!");
                    });
                } else message.error("Something wrong. Please try again...!");
            });
        }
        else {
            if (selectedOption === "player2") {
                const data = {
                    winningAmount: decodedData.winningAmount + wallet2.winningAmount,
                    winningFreezAmount: 0
                }
                axiosInstance.put(`wallet/${decodedData.game.player2}`, data).then((res) => {
                    if (res.data && res.data.responseCode === 1) {
                        const data1 = {
                            status: "solved"
                        }
                        axiosInstance.put(`game2F/${decodedData._id}`, data1).then((res) => {
                            if (res.data && res.data.responseCode === 1) {
                                const data2 = {
                                    status: "success",
                                    player1Status: "looser",
                                    player2Status: "winner",
                                    winner: "player2",
                                }
                                axiosInstance.put(`game2/${decodedData.game._id}`, data2).then((res) => {

                                    if (res.data && res.data.responseCode === 1) {
                                        message.success("Success");
                                        navigate('/fCGame1');

                                    } else message.error("Something wrong. Please try again...!");
                                });
                            } else message.error("Something wrong. Please try again...!");
                        });
                    } else message.error("Something wrong. Please try again...!");
                });
            }
            else {
                if (selectedOption === "cancel") {
                    const plntData = {
                        user1Id:decodedData.game.player1,
                        user2Id:decodedData.game.player2,
                        palenty:decodedData.gameAmount * .1,
                        plntMember:"Both"
                    }
                    AddPalentyAmount(plntData)
                    const data = {
                        depositeAmount: decodedData.gameAmount + wallet2.depositeAmount
                            - (decodedData.gameAmount * .1),
                        winningFreezAmount: 0
                    }
                    axiosInstance.put(`wallet/${decodedData.game.player2}`, data).then((res) => {
                        if (res.data && res.data.responseCode === 1) {
                            const data1 = {
                                status: "solved"
                            }
                            axiosInstance.put(`game2F/${decodedData._id}`, data1).then((res) => {
                                if (res.data && res.data.responseCode === 1) {
                                    const data2 = {
                                        status: "success",
                                        player1Status: "looser",
                                        player2Status: "looser",
                                        winner: "Tie",
                                    }
                                    axiosInstance.put(`game2/${decodedData.game._id}`, data2).then((res) => {

                                        if (res.data && res.data.responseCode === 1) {
                                            const data3 = {
                                                depositeAmount: decodedData.gameAmount + wallet1.depositeAmount - (decodedData.gameAmount * .1),
                                                winningFreezAmount: 0
                                            }
                                            console.log("data3",data3)
                                            axiosInstance.put(`wallet/${decodedData.game.player1}`, data3).then((res) => {
                                                if (res.data && res.data.responseCode === 1) {
                                                    message.success("Success");
                                                    navigate('/fCGame2');
                                                } else message.error("Something wrong. Please try again...!");
                                            });
                                        } else message.error("Something wrong. Please try again...!");
                                    });
                                } else message.error("Something wrong. Please try again...!");
                            });
                        } else message.error("Something wrong. Please try again...!");
                    });
                }
                else {
                    if (selectedOption === "cancelFree") {
                        const data = {
                            depositeAmount: decodedData.gameAmount + wallet2.depositeAmount,
                            winningFreezAmount: 0
                        }
                        axiosInstance.put(`wallet/${decodedData.game.player2}`, data).then((res) => {
                            if (res.data && res.data.responseCode === 1) {
                                const data1 = {
                                    status: "solved"
                                }
                                axiosInstance.put(`game2F/${decodedData._id}`, data1).then((res) => {
                                    if (res.data && res.data.responseCode === 1) {
                                        const data2 = {
                                            status: "success",
                                            player1Status: "looser",
                                            player2Status: "looser",
                                            winner: "Tie",
                                        }
                                        axiosInstance.put(`game2/${decodedData.game._id}`, data2).then((res) => {
    
                                            if (res.data && res.data.responseCode === 1) {
                                                const data3 = {
                                                    depositeAmount: decodedData.gameAmount + wallet1.depositeAmount,
                                                    winningFreezAmount: 0
                                                }
                                                console.log("data3",data3)
                                                axiosInstance.put(`wallet/${decodedData.game.player1}`, data3).then((res) => {
                                                    if (res.data && res.data.responseCode === 1) {
                                                        message.success("Success");
                                                        navigate('/fCGame2');
                                                    } else message.error("Something wrong. Please try again...!");
                                                });
                                            } else message.error("Something wrong. Please try again...!");
                                        });
                                    } else message.error("Something wrong. Please try again...!");
                                });
                            } else message.error("Something wrong. Please try again...!");
                        });
                    }
                    else{
                        if(selectedOption === "rp1"){
                            const plntData = {
                               
                                user2Id:decodedData.game.player2,
                                palenty:decodedData.gameAmount ,
                                plntMember:"p2"
                            }
                            AddPalentyAmount(plntData)
                            const data = {
                                depositeAmount: decodedData.gameAmount + wallet1.depositeAmount,
                                winningFreezAmount: 0
                            }
                            axiosInstance.put(`wallet/${decodedData.game.player1}`, data).then((res) => {
                                if (res.data && res.data.responseCode === 1) {
                                    const data1 = {
                                        status: "solved"
                                    }
                                    axiosInstance.put(`game2F/${decodedData._id}`, data1).then((res) => {
                                        if (res.data && res.data.responseCode === 1) {
                                            const data2 = {
                                                status: "success",
                                                player1Status: "winner",
                                                player2Status: "looser",
                                                winner: "rp1",
                                            }
                                            axiosInstance.put(`game2/${decodedData.game._id}`, data2).then((res) => {
                
                                                if (res.data && res.data.responseCode === 1) {
                                                    message.success("Success");
                                                    navigate('/fCGame1');
                
                                                } else message.error("Something wrong. Please try again...!");
                                            });
                                        } else message.error("Something wrong. Please try again...!");
                                    });
                                } else message.error("Something wrong. Please try again...!");
                            });
                        
                        }
                        else{
                            if(selectedOption === 'rp2'){
                                const plntData = {
                                    user1Id:decodedData.game.player1,
                                    user2Id:decodedData.game.player2,
                                    palenty:decodedData.gameAmount,
                                    plntMember:"p1"
                                }
                                AddPalentyAmount(plntData)
                                const data = {
                                    depositeAmount: decodedData.gameAmount + wallet2.depositeAmount,
                                    winningFreezAmount: 0
                                }
                                axiosInstance.put(`wallet/${decodedData.game.player2}`, data).then((res) => {
                                    if (res.data && res.data.responseCode === 1) {
                                        const data1 = {
                                            status: "solved"
                                        }
                                        axiosInstance.put(`game2F/${decodedData._id}`, data1).then((res) => {
                                            if (res.data && res.data.responseCode === 1) {
                                                const data2 = {
                                                    status: "success",
                                                    player1Status: "looser",
                                                    player2Status: "winner",
                                                    winner: "rp2",
                                                }
                                                axiosInstance.put(`game2/${decodedData.game._id}`, data2).then((res) => {
                    
                                                    if (res.data && res.data.responseCode === 1) {
                                                        message.success("Success");
                                                        navigate('/fCGame1');
                    
                                                    } else message.error("Something wrong. Please try again...!");
                                                });
                                            } else message.error("Something wrong. Please try again...!");
                                        });
                                    } else message.error("Something wrong. Please try again...!");
                                });
                            }
                        }
                    }
                }
            }
        }

    };


    const AddPalentyAmount = (e) =>{
        if(e.plntMember == "Both"){
            AddPalentyP1(e)
            AddPalentyP2(e)
        }
        if(e.plntMember == "p1"){
            AddPalentyP1(e)
        }
        if(e.plntMember == "p2"){
            AddPalentyP2(e)
        }
    }

    const AddPalentyP1 = (e) =>{
       if(wallet1.palenty == undefined){
        const data1 ={
            palenty : e.palenty
        } 
        axiosInstance.put(`/wallet/${e.user1Id}`, data1).then((res) => {
            console.log("res",res)
          });
       }
       else{
        const data1 ={
            palenty :wallet1.palenty + e.palenty
        } 
        axiosInstance.put(`/wallet/${e.user1Id}`, data1).then((res) => {
            console.log("res",res)
          });

       }
       
    }


    const AddPalentyP2 = (e) =>{
        if(wallet2.palenty == undefined){
            const data1 ={
                palenty : e.palenty
            } 
            axiosInstance.put(`/wallet/${e.user2Id}`, data1).then((res) => {
                console.log("res",res)
              });
           }
           else{
            const data1 ={
                palenty :wallet2.palenty + e.palenty
            } 
            axiosInstance.put(`/wallet/${e.user2Id}`, data1).then((res) => {
                console.log("res",res)
              });
    
           }
           
    }
    return (
        <div>
            <label htmlFor="selectOptions">Select an Winner:</label>
            <select id="selectOptions" value={selectedOption} onChange={handleSelectChange}>
            <option value="">Select...</option>
                <option value="player1">player 1</option>
                <option value="player2">player 2</option>
                <option value="cancel">Cancel Game</option>
                <option value="cancelFree">Cancel Without Commission Game</option>
                <option value="rp1">Refund Player 1</option>
                <option value="rp2">Refund Player 2</option>
            </select>

            <button onClick={() => Confirm()}>Confirm</button>
        </div>
    );
}

export default SolveCGame2;
